import swal from 'sweetalert';
import React from 'react';
import Moment from 'react-moment';
import userImage from '../assets/images/user-image.png';
import impexoImage from '../assets/images/logo.png';
import impexoImageBanner from '../assets/images/news1.jpg';

import moment from 'moment';
import { frontEndURL } from './Constants';

export const parstDateTime = (date) => {
  if (date && date !== '') {
    // Convert the UTC timestamp to a Moment object
    const momentObj = moment.utc(date);
    // Get the human-readable time difference using the 'fromNow' function
    const diff = momentObj.fromNow();
    return diff;
  } else {
    return null;
  }
};

export const wildcardFilterArray = (str, array) =>
  array.filter((item) =>
    new RegExp('^' + str.toLowerCase().replace(/\*/g, '.*') + '$').test(item.toLowerCase())
  );

/**
 *
 * @param {*} str A string value which is checked against item inside array.
 * @param {*} array An array of objects with value id and name.
 * @description This method is mostly used by Combobox component to filter an array of objects by string value input by user.
 */

export const wildcardFilterObjectArray = (str, array) =>
  array.filter((item) =>
    new RegExp('^' + str.toLowerCase().replace(/\*/g, '.*') + '$').test(item.name.toLowerCase())
  );

/**
 *
 * @param {HttpResponse} response
 * @description Displays an error alert box. Built specifically for displaying http error message.
 */
export const displayErrorAlert = (err) => {
  console.log(err);
  if (err?.message === 'Duplicate Request') {
    return;
  } else {
    swal(
      'Error',
      err && err.response && err.response.data && err.response.data.message
        ? err.response.data.message
        : 'Some error occured',
      'error'
    );
  }
};
export const displayNoRecordAlert = () => {
  swal('Error', 'No Roles for this Department', 'error');
};
export const displayInfoAlert = (messageHeader, message) => {
  swal(messageHeader, message, 'info');
};
export const displayWarningInfoAlert = (message) => {
  swal('Warning', message, 'warning');
};

export const displaySuccessAlert = (response, callback, param) => {
  swal('Success', response.data.message, 'success').then(() => {
    if (callback) callback(param);
  });
};

export const displayConfirmDeleteAlert = (params, handleDelete) => {
  swal({
    title: 'Are you sure?',
    text: 'Once deleted, you will not be able to recover this record!',
    icon: 'warning',
    buttons: true,
    dangerMode: true
  }).then((deleteConfirm) => {
    if (deleteConfirm) {
      handleDelete(params);
    } else {
      swal('Your record is safe!');
    }
  });
};

export const displayConfirmDeleteSelectedAlert = (getSelectedItems, handleDeleteSelected) => {
  swal({
    title: 'Are you sure?',
    text: 'Once deleted, you will not be able to recover this record!',
    icon: 'warning',
    buttons: true,
    dangerMode: true
  }).then((deleteConfirm) => {
    if (deleteConfirm) {
      let params = getSelectedItems();
      handleDeleteSelected(params);
    } else {
      swal('Your record is safe!');
    }
  });
};

export const getStyleForModal = () => {
  return {
    minHeight: '2000px',
    minWeight: '2000px'
  };
};

export const formatDate = (date) => {
  return (
    <Moment format="ll" withTitle>
      {date}
    </Moment>
  );
};

export const handleError = (e) => {
  e.target.src = userImage;
};

export const handleCompanyImageError = (e) => {
  e.target.src = impexoImage;
};

export const convertCamelCaseToSentenceCase = (camelCase) => {
  // adding space between strings
  const result = camelCase.replace(/([A-Z])/g, ' $1');

  // converting first character to uppercase and join it to the final string
  const sentenceCase = result.charAt(0).toUpperCase() + result.slice(1);
  return sentenceCase;
};

export const bytesToMegaBytes = (bytes) => bytes / (1024 * 1024);

export const getPersonProfileFullName = (pp) => {
  if (pp) {
    const pP = pp.personProfile;
    if (pP.middleName !== '' || pP.middleName !== null) {
      return `${pP.firstName} ${pP.middleName} ${pP.lastName}`;
    } else {
      return `${pP.firstName} ${pP.lastName}`;
    }
  } else {
    return '';
  }
};

export const getFullName = (f = '', m = '', l = '') => {
  if (f && l && f !== '' && f !== null && l !== '' && l !== null) {
    if (m !== '' || m !== null) {
      return `${f} ${m} ${l}`;
    } else {
      return `${f} ${l}`;
    }
  } else {
    if (f) {
      return f;
    } else {
      return null;
    }
  }
};

export const timezoneStringHandler = (s) => {
  return s ? s.replace(/[\])}[{(]/g, '') : '';
};

//function to convert camel case to normal case
function camelToNormalCase(camel) {
  let spaced = camel.replace(/([a-z])([A-Z])/g, '$1 $2');
  spaced = spaced.charAt(0).toUpperCase() + spaced.slice(1);
  return spaced;
}

//function to retun array with collection of {name,value} pair instead of key value in object
export const keyWithCamelCaseAndValue = (obj, filterName = 'Remarks') => {
  const filteredObj = Object.fromEntries(
    Object.entries(obj).filter(([key]) => key.includes(filterName))
  );
  return Object.entries(filteredObj)
    .map(([key, value]) => {
      const newKey = camelToNormalCase(key);
      return { name: newKey, value: value };
    })
    .filter((item) => item.value !== null);
};
// function to check expiration of date validity with present date
export const isExpired = (expirationDate) => {
  const currentDate = new Date().getTime();
  const expiration = new Date(expirationDate);
  const expirationTime = expiration.getTime();
  return expirationTime < currentDate;
};

export const totalGrossWeight = (containerDetail) => {
  const weightInKg = containerDetail
    ?.filter((item) => item.grossWeightUnit !== 'Lbs')
    ?.map((item) => (item.grossWeight === '' || item.grossWeight === null ? 0 : item.grossWeight));
  const convertedLbtoKg = containerDetail
    ?.filter((item) => item.grossWeightUnit === 'Lbs')
    ?.map((item) =>
      item.grossWeight === '' || item.grossWeight === null ? 0 : item.grossWeight * 0.4536
    );
  const totalConvertedKgWeightCollection = weightInKg.concat(convertedLbtoKg);
  var totalGrossWeight = totalConvertedKgWeightCollection.reduce((accumulator, currentValue) => {
    return accumulator + parseFloat(currentValue);
  }, 0);

  return `${totalGrossWeight} kg`;
};

export const totalCbm = (containerDetail) => {
  const totalCbmCollection = containerDetail?.map((item) => item.cbm);
  var totalCbm = totalCbmCollection.reduce((accumulator, currentValue) => {
    return accumulator + parseFloat(currentValue);
  }, 0);
  return `${formatNumberWithPrecision(totalCbm)} cbm`;
};
// checks if all object is empty or null and return true and false
export const checkAllKeys = (obj) => {
  const values = Object.values(obj);
  return values.every((value) => value === '' || value === null);
};

export const checkAllKeysNotEmpty = (obj) => {
  const values = Object.values(obj);
  return values.every((value) => value !== '' && value !== null);
};

export const splitAndJoin = (key) => {
  if (!!key) {
    return key?.split(' ')?.join('')?.trim();
  } else {
    return null;
  }
};

export const scrollToBottom = () => {
  const objDiv = document.getElementById('scroll-bottom');
  if (objDiv) {
    const scrollHeight = objDiv.scrollHeight;
    const scrollTop = objDiv.scrollTop;
    const clientHeight = objDiv.clientHeight;
    const maxScrollTop = scrollHeight - clientHeight;
    objDiv.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  }
};

export const scrollToTop = () => {
  const objDiv = document.getElementById('scroll-top');
  if (objDiv) {
    objDiv.scrollTop = 0;
  }
};

export const calculateContainerNumber = (data) => {
  const filteredData = data.map((item) => item?.numberOfContainer);
  const totalNo = filteredData?.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );
  return totalNo ?? 0;
};

export const formatNumberWithPrecision = (num) => {
  if (!!!num) {
    return num;
  }
  let number = parseFloat(num);
  if (!!num) {
    if (number % 1 !== 0) {
      const [wholePart, fractionalPart] = number.toString().split('.');
      if (fractionalPart?.length > 4) {
        return parseFloat(`${wholePart}.${fractionalPart.slice(0, 4).padEnd(4, '0')}`);
      } else {
        return number;
      }
    } else {
      return number;
    }
  } else {
    return number;
  }
};

export const isDecimalLengthGreaterThanFour = (value) => {
  const numberStr = value.toString(); // Convert to string for manipulation
  const parts = numberStr.split('.'); // Split at the decimal point
  // Check if there's a decimal part and its length is greater than 4
  return parts?.length > 1 && parts[1]?.length > 4;
};

export const formatsentDate = (date) => {
  if (!!date) {
    return `${date?.getFullYear()}-${String(date?.getMonth() + 1).padStart(2, '0')}-${String(date?.getDate()).padStart(2, '0')}`;
  }
  return null;
};
export const formatPhoneNumber = (num) => {
  if (num?.length > 0) {
    let numbers = num.split(' ');
    if (num.includes('(')) {
      const remainingNumbers = numbers.slice(2);
      const joinedString = remainingNumbers.join('');
      let finalNumber = `${numbers?.[0]}_${numbers?.[1]}_${joinedString}`;
      return finalNumber;
    } else {
      const remainingNumbers = numbers.slice(1);
      const joinedString = remainingNumbers.join('');
      let finalNumber = `${numbers?.[0]}_${joinedString}`;
      return finalNumber;
    }
  } else {
    return num;
  }
};

export function findLowestDate(dates) {
  
  if (dates.length <= 0 || dates == undefined || dates == null) {
    return null;
  }
  // Convert the date strings to Date objects
  const dateObjects = dates.map((dateString) => new Date(dateString));

  // Sort the dates in ascending order
  const sortedDates = dateObjects.sort((a, b) => a - b);

  // Return the lowest date
  return sortedDates[0];
}

// Example usage
// const dates = ["2024-09-28", "2024-10-01", "2024-09-25"];
// console.log(findLowestDate(dates));

// added for browser notifications
export const browserNotifications = (title, body, link, toUserId, notificationId) => {
  if (!window.Notification) {
    console.log('Browser does not support notifications.');
  } else {
    if (Notification.permission === 'granted') {
      var notify = new Notification(title, {
        body: body,
        icon: impexoImage,
        badge: impexoImage,
        requireInteraction: false,
        tag: `notificationChannel.${toUserId}.${notificationId}`,
        silent: true
        // image:impexoImageBanner
      });
      notify.onclick = (e) => {
        e.preventDefault();
        window.open(frontEndURL + link);
      };
    } else {
      Notification.requestPermission()
        .then(function (p) {
          if (p === 'granted') {
            var notify = new Notification(title, {
              body: body,
              icon: impexoImage,
              badge: impexoImage,
              requireInteraction: false,
              tag: `notificationChannel.${toUserId}.${notificationId}`,
              silent: true
              // image:impexoImageBanner
            });
            notify.onclick = (e) => {
              e.preventDefault();
              window.open(frontEndURL + link);
            };
          } else {
            // console.log('User blocked notifications.');
          }
        })
        .catch(function (err) {
          console.error(err);
        });
    }
  }
};

export const extractIdFromUrlParam = (param) => {
  // Split the parameter at '&' to separate individual parameters
  const params = param.split('&');

  // Find the 'id' parameter
  const idParam = params.find((p) => p.startsWith('readNum='));

  // If found, return the value after '='
  if (idParam) {
    return idParam.split('=')[1];
  }

  // If not found, return null
  return null;
};

// function to add grossWeight of multiple containers, calculate total by converting into Kgs
export const addContainerGrossWeight = (container) => {
  let containerTotalWeight = 0;
  container.forEach((item) => {
    let weight = Number(item.grossWeight);
    if (item.grossWeightUnit === 'Lbs') {
      weight = weight * 0.453592;
    }
    containerTotalWeight += weight;
  });
  return `${formatNumberWithPrecision(containerTotalWeight)} Kgs`;
};

// function added to calculate UTC time in Nepal Time
export const formatDateTime = (discussion, type) => {
  // console.log(discussion);
  if (discussion) {
    const date = new Date(discussion.createdAt || discussion.created_at);
    // console.log("Original UTC Date:", date.toUTCString());

    const timeZoneVar = discussion?.timeZone;
    const match = timeZoneVar?.match(/GMT([+-]\d{2})(\d{2})/);

    if (match) {
      const hoursOffset = parseInt(match[1], 10) + 5 - 12;
      const minutesOffset = parseInt(match[2], 10) + 30;
      const totalOffsetInMinutes = hoursOffset * 60 + minutesOffset + 45;

      date.setMinutes(date.getMinutes() + totalOffsetInMinutes);
      // console.log("Adjusted Date:", date.toString());
    }

    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };

    const optionsDate = {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };

    const optionsTime = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };

    if (type === 'dateTime') {
      return date.toLocaleString('en-US', options);
    }
    if (type === 'date') {
      return date.toLocaleString('en-US', optionsDate);
    } else if (type === 'time') {
      return date.toLocaleString('en-US', optionsTime);
    }
  }
};
